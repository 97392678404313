<script>
import { reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const stage = reactive({
      idx: store.state.idx_staff,
      email: store.state.email,
      kname: store.state.kname,
      ename: store.state.ename,

      // 스테이지 서버
      goStageServer(url) {
        window.open(url, "_blank");
      },
    });
    onMounted(() => {
      // Mounted
      if (!stage.email || stage.email === "") {
        alert("로그인 후 접속해주세요");
        router.push({ path: "/login", params: {} });
      }
    });

    return { stage };
  },
};
</script>
<template>
  <div class="stage-wrapper">
    <h3>
      Stage (테스트 서버)
      <p>개발 진행중인 신규/추가 서비스를 테스트 하실 수 있습니다.</p>
    </h3>
    <div class="list-wrapper">
      <ul class="main-list">
        <!-- 글로벌 -->
        <li>
          <p class="title global">글로벌 (테스트)</p>
          <ul class="sub-list global">
            <li
              @click="
                stage.goStageServer(
                  `https://stage.carrotbpo.com/apply/login`
                )
              "
            >
              삼성전자DS BPO 관리자 삼성
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage.carrotbpo.com/carrot/login?email=${stage.email}`
                )
              "
            >
              삼성전자DS BPO 관리자 캐럿
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stagestaff.carrotbpo.com/staff/login`
                )
              "
            >
              삼성전자DS BPO 관리자 Staff
            </li>
          </ul>
          <ul class="sub-list global">
            <li
              class="user"
              @click="stage.goStageServer(`http://stage.ktngleader.com/`)"
            >
              KT&G 사용자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://stage-admin.ktngleader.com/connectionHQ?email=${stage.email}`
                )
              "
            >
              KT&G 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://stage-admin.carrotleaders.com/connectionHQ?email=${stage.email}`
                )
              "
            >
              리더십 진단평가 관리자
            </li>
          </ul>
          <ul class="sub-list global">
            <li
              class="user"
              @click="
                stage.goStageServer('http://stagest.carrotenglish.net/login')
              "
            >
              ITM 사용자
            </li>

            <li
              @click="
                stage.goStageServer(`https://stage-myclass.carrotenglish.net/`)
              "
            >
              마이클래스앱(테스트전용)
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stagehq.carrotenglish.net/auth/common/directLink?uid=${stage.email}`
                )
              "
            >
              ITM HQ 매니저
            </li>
            <li
              @click="
                stage.goStageServer(
                  'https://stageteacher.carrotenglish.net/login'
                )
              "
            >
              ITM 강사
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-itmtutorhq.carrotenglish.net/login?email=${stage.email}&idx=${stage.idx}`
                )
              "
            >
              New ITM HQ
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-itmtutorweb.carrotenglish.net/`
                )
              "
            >
              New ITM App
            </li>
          </ul>
          <ul class="sub-list global">
            <li
              class="user"
              @click="
                stage.goStageServer(`https://stage-langlab.carrotglobal.com/`)
              "
            >
              랭기지랩 사용자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-langlab-admin.carrotglobal.com/login?uid=${stage.email}`
                )
              "
            >
              랭기지랩 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-langlab-tutor.carrotglobal.com/`
                )
              "
            >
              랭기지랩 강사
            </li>
          </ul>
        </li>

        <!-- 전화/화상영어 -->
        <li>
          <p class="title call-english">전화/화상영어 (테스트)</p>
          <ul class="sub-list call-english">
            <li
              class="user"
              @click="stage.goStageServer('https://stage.carrotenglish.kr/')"
            >
              당근영어 성인
            </li>
            <li
              class="user"
              @click="
                stage.goStageServer('https://stage.carrotjunior.kr/index?')
              "
            >
              당근영어 주니어
            </li>
          </ul>
          <ul class="sub-list call-english">
            <li
              class="user"
              @click="stage.goStageServer('https://stage.carrotchinese.com')"
            >
              당근중국어 랜딩
            </li>
          </ul>
          <ul class="sub-list call-english">
            <li
              @click="
                stage.goStageServer(
                  `https://stagectm.carrotenglish.kr/act/outlogin?email=${stage.email}`
                )
              "
            >
              CTM HQ 매니저
            </li>
            <li
              @click="
                stage.goStageServer(
                  'https://stagetctm.carrotenglish.kr/act/login'
                )
              "
            >
              CTM 강사
            </li>
            <li
              @click="
                stage.goStageServer(
                  'https://stage-biz.carrotenglish.com/ctm_tutor_app/down/'
                )
              "
            >
              CTM 강사 앱 다운로드
            </li>
            <li
              @click="
                stage.goStageServer('https://stage.carrotenglishtutor.com/')
              "
            >
              강사채용
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-mediabook-admin.carrotenglish.kr/connectionHQ?email=${stage.email}`
                )
              "
            >
              NEW 미디어교재
            </li>
          </ul>
          <ul class="sub-list call-english">
            <li
              class="user"
              @click="stage.goStageServer('http://stage-carrotfarm.carrotenglish.com/')"
            >
              New 당근농장 App 
            </li>
          </ul>          
        </li>

        <!-- 에듀테크 -->
        <li>
          <p class="title edutech">에듀테크 (테스트)</p>
          <ul class="sub-list edutech">
            <li
              class="user"
              @click="stage.goStageServer(`https://stage.malangtalk.com`)"
            >
              말랑톡 랜딩
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-admin.malangtalk.com/connectionHQ?email=${stage.email}`
                )
              "
            >
              말랑톡 관리자
            </li>
          </ul>
          <ul class="sub-list edutech">
            <li
              class="user"
              @click="stage.goStageServer('https://www.liveesl.co.kr/')"
            >
              i:MOOC
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://admin.liveesl.co.kr/rest/auth/login?email=${stage.email}&kname=${stage.kname}`
                )
              "
            >
              i:MOOC 관리자
            </li>
          </ul>
          <ul class="sub-list edutech">
            <li
              class="user"
              @click="
                stage.goStageServer(
                  `http://prontest.carrotenglish.net/outlogin?idx=${stage.idx}&uid=${stage.email}&ename=${stage.ename}`
                )
              "
            >
              Pronunciation Evaluate Test
            </li>
          </ul>
          <ul class="sub-list edutech">
            <li
              @click="
                stage.goStageServer('https://carrotglobal.com/biz_english/down')
              "
            >
              맞춤표현집 앱 다운로드
            </li>
            <li
              @click="
                stage.goStageServer('https://stage-expadmin.carrotenglish.net/')
              "
            >
              맞춤표현집 관리자
            </li>
          </ul>
          <ul class="sub-list edutech">
            <li
              @click="
                stage.goStageServer(
                  `https://stageitm.carrotenglish.net/rest/mr8/admin_ssologin?email=${stage.email}`
                )
              "
            >
              팔선생 관리자
            </li>
          </ul>
        </li>

        <!-- MangoTalk -->
        <li>
          <p class="title mango-talk">MangoTalk (테스트)</p>
          <ul class="sub-list mango-talk">
            <li
              class="user"
              @click="stage.goStageServer(`https://stage.mangotalk.co.kr`)"
            >
              랜딩
            </li>
            <li
              class="user"
              @click="
                stage.goStageServer(
                  `https://stage-app.mangotalk.co.kr/Login/Home`
                )
              "
            >
              사용자
            </li>
            <li
              @click="
                stage.goStageServer(`https://stage-tutor.mangotalk.co.kr`)
              "
            >
              Tutor 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-hq.mangotalk.co.kr/login?email=${stage.email}&idx=${stage.idx}`
                )
              "
            >
              HQ 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-topic-admin.mangotalk.co.kr/act/outlogin?email=${stage.email}`
                )
              "
            >
              교재관리
            </li>
            <li
              @click="
                stage.goStageServer(`http://stage-recruit.mangotalk.co.kr`)
              "
            >
              강사채용
            </li>
          </ul>
        </li>

        <!-- CARROT -->
        <li>
          <p class="title carrot">CARROT (테스트)</p>
          <ul class="sub-list carrot">
            <li
              class="user"
              @click="
                stage.goStageServer(`https://stage-biz.carrotglobal.com/`)
              "
            >
              CARROT Global
            </li>
            <li
              class="user"
              @click="
                stage.goStageServer(`https://stage-biz.carrotglobal.com/Global`)
              "
            >
              기업교육:글로벌 역량
            </li>
            <li
              class="user"
              @click="
                stage.goStageServer(
                  `https://stage-biz.carrotglobal.com/Introduce`
                )
              "
            >
              회사소개
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-biz.carrotglobal.com/Admin/News`
                )
              "
            >
              보도자료 게시판 관리자
            </li>
          </ul>
          <ul class="sub-list carrot">
            <li
              class="user"
              @click="
                stage.goStageServer(`https://stage-biz.carrotenglish.com/`)
              "
            >
              전화·화상외국어
            </li>
            <li
              class="user"
              @click="
                stage.goStageServer(
                  `https://stage-biz.carrotenglish.com/edu-tech`
                )
              "
            >
              온라인·AI과정
            </li>
          </ul>
          <ul class="sub-list carrot">
            <li
              class="user"
              @click="
                stage.goStageServer(`https://stage-conf2024.carrotglobal.com`)
              "
            >
              2024 컨퍼런스
            </li>
          </ul>
          <ul class="sub-list carrot">
            <li
              class="user"
              @click="
                stage.goStageServer(
                  `http://stage-hrd.carrotians.net/connectionHQ?email=${stage.email}`
                )
              "
            >
              컨퍼런스 운영 관리자
            </li>
          </ul>
        </li>

        <!-- 수강신청/survey -->
        <li>
          <p class="title survey">수강신청/survey (테스트)</p>
          <ul class="sub-list survey">
            <li
              @click="
                stage.goStageServer(
                  `https://stage-integrated-sugang-admin.carrotapply.com/login?email=${stage.email}&idx=${stage.idx}`
                )
              "
            >
              통합수강신청 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://stage-itmsugang-admin.carrotenglish.net/users/carrot-login?email=${stage.email}&idx=${stage.idx}`
                )
              "
            >
              ITM 수강신청 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://applystage.carrotenglish.net/rest/auth/login?kname=${stage.kname}&email=${stage.email}`
                )
              "
            >
              CTM 수강신청 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://stage.carrotsurvey.com/rest/common/loginHQ?uid=${stage.email}&returnUrl=/admin/index/`
                )
              "
            >
              CARROT Survey
            </li>
          </ul>
        </li>

        <!-- 고객사 전용 -->
        <li>
          <p class="title customer">고객사 전용 (테스트)</p>
          <ul class="sub-list customer">
            <li
              @click="
                stage.goStageServer(
                  `https://stage-lifeedu-admin.carrotenglish.net/connectionHQ?email=${stage.email}&${stage.idx}`
                )
              "
            >
              평생교육 관리자
            </li>
          </ul>
          <ul class="sub-list customer">
            <li
              class="user"
              @click="stage.goStageServer(`https://stage.carrotskbiosc.co.kr`)"
            >
              SK바이오사이언스
            </li>
          </ul>
          <ul class="sub-list customer">
            <li
              class="user"
              @click="stage.goStageServer(`http://stage.gijangcamp.co.kr`)"
            >
              기장군민어학당
            </li>
          </ul>
        </li>

        <!-- SPAC^ -->
        <li>
          <p class="title spac">SPAC^ (테스트)</p>
          <ul class="sub-list spac">
            <li
              class="user"
              @click="
                stage.goStageServer(
                  `https://stagespac-student.carrotglobal.com/`
                )
              "
            >
              Writing 사용자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://stagespac-hq.carrotglobal.com/rest/common/autoLogin?uid=${stage.email}&type=hq`
                )
              "
            >
              Writing HQ 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://stagespac-rater.carrotglobal.com/rest/common/autoLogin?uid=${stage.email}&type=rater`
                )
              "
            >
              Writing 평가자
            </li>
          </ul>

          <ul class="sub-list spac">
            <li
              class="user"
              @click="stage.goStageServer(`https://stage-landing.thespac.net`)"
            >
              New SPAC^
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://stage-admin.thespac.net/connectionHQ?email=${stage.email}`
                )
              "
            >
              New SPAC^ 관리자
            </li>
            <li @click="stage.goStageServer(`https://stage-admin.thespac.net`)">
              New SPAC^ 평가자
            </li>
          </ul>
        </li>

        <!-- 밝음 -->
        <li>
          <p class="title bright">밝음 (테스트)</p>
          <ul class="sub-list bright">
            <li
              class="user"
              @click="stage.goStageServer(`http://stage.bhappy.co.kr`)"
            >
              밝음
            </li>
            <li
              @click="
                stage.goStageServer(`http://stagecrm.carrotenglish.net/login`)
              "
            >
              CRM(상담사,관리자,센터장) 관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `http://adminstage.bhappy.co.kr/act/outlogin?email=${stage.email}`
                )
              "
            >
              EAP 관리자
            </li>
          </ul>
        </li>

        <!-- 스피킹랩 -->
        <li>
          <p class="title speaking-lab">스피킹랩 (테스트)</p>
          <ul class="sub-list speaking-lab">
            <li
              @click="
                stage.goStageServer(
                  `https://stagespeaking2.carrotenglish.net/auth/3oe8rh?kname=${stage.kname}&uidx=${stage.idx}`
                )
              "
            >
              관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://kr.object.ncloudstorage.com/speakinglab2/android_apk/speaking_lab_20220310.apk`
                )
              "
            >
              Android 앱 다운로드
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://apps.apple.com/us/app/speakinglab/id6463774714`
                )
              "
            >
              IOS 앱 다운로드
            </li>
          </ul>
        </li>

        <!-- BNB -->
        <li>
          <p class="title bnb">BNB (테스트)</p>
          <ul class="sub-list bnb">
            <li
              @click="
                stage.goStageServer(
                  `https://bnb-stage-admin.carrotglobal.com/?userId=${stage.email}`
                )
              "
            >
              관리자
            </li>
            <li
              @click="
                stage.goStageServer(
                  `https://bnb-stage-admin.carrotglobal.com/manager/login`
                )
              "
            >
              담당자
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.stage-wrapper {
  background-color: #fafafa;
  padding: 58px 0;

  h3 {
    font-size: 26px;
    font-weight: 700;
    color: #555555;
    text-align: center;
    margin-bottom: 70px;
  }

  .list-wrapper {
    width: 900px;
    margin: 0 auto;

    .main-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;

      > li {
        .title {
          display: inline-block;
          font-size: 18px;
          color: #fff;
          padding: 6px 24px;
          border-radius: 18px 18px 18px 0;
          margin-bottom: 12px;

          &.global {
            background-color: #67afde;
          }
          &.call-english {
            background-color: #fe5003;
          }
          &.edutech {
            background-color: #4f46e5;
          }
          &.mango-talk {
            background-color: #f49500;
          }
          &.carrot {
            background-color: #ff8a18;
          }
          &.survey {
            background-color: #fe8f73;
          }
          &.customer {
            background-color: #374151;
          }
          &.spac {
            background-color: #d773fe;
          }
          &.bright {
            background-color: #f2be4c;
          }
          &.speaking-lab {
            background-color: #4ba0da;
          }
          &.bnb {
            background-color: #4cda64;
          }
        }

        .sub-list {
          box-shadow: 0px 0px 8px rgba(158, 158, 158, 0.14);
          border-radius: 0 14px 14px 14px;
          background-color: #fff;
          overflow: hidden;

          &:not(:last-of-type) {
            margin-bottom: 16px;
          }

          &.global > li {
            &:hover {
              color: #67afde;
            }

            &.user {
              &::before {
                background: #67afde;
              }
            }
          }
          &.call-english > li {
            &:hover {
              color: #fe5003;
            }

            &.user {
              &::before {
                background: #fe5003;
              }
            }
          }
          &.edutech > li {
            &:hover {
              color: #4f46e5;
            }

            &.user {
              &::before {
                background: #4f46e5;
              }
            }
          }
          &.mango-talk > li {
            &:hover {
              color: #f49500;
            }

            &.user {
              &::before {
                background: #f49500;
              }
            }
          }
          &.carrot > li {
            &:hover {
              color: #ff8a18;
            }

            &.user {
              &::before {
                background: #ff8a18;
              }
            }
          }
          &.survey > li {
            &:hover {
              color: #fe8f73;
            }

            &.user {
              &::before {
                background: #fe8f73;
              }
            }
          }
          &.customer > li {
            &:hover {
              color: #374151;
            }

            &.user {
              &::before {
                background: #374151;
              }
            }
          }
          &.spac > li {
            &:hover {
              color: #d773fe;
            }

            &.user {
              &::before {
                background: #d773fe;
              }
            }
          }
          &.bright > li {
            &:hover {
              color: #f2be4c;
            }

            &.user {
              &::before {
                background: #f2be4c;
              }
            }
          }
          &.speaking-lab > li {
            &:hover {
              color: #4ba0da;
            }

            &.user {
              &::before {
                background: #4ba0da;
              }
            }
          }
          &.bnb > li {
            &:hover {
              color: #4cda64;
            }

            &.user {
              &::before {
                background: #4cda64;
              }
            }
          }

          > li {
            font-size: 16px;
            color: #555;
            padding: 16px 16px 16px 24px;
            cursor: pointer;

            &.user {
              position: relative;
              font-size: 18px;
              font-weight: 500;

              &::before {
                content: "";
                width: 3px;
                height: 22px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }

            &:not(:last-of-type) {
              border-bottom: #e5e5e5 1px solid;
            }
          }
        }
      }
    }
  }
}
</style>
